.fiiFnO {
}
.fiiFnoOuterContainer {
    background-color: #1e2124;
    padding: calc(0.5vh + 0.5vw);
}
.fiiFnoInnerContainer {
    background-color: #010101;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.fiiDiiTable {
    padding: calc(0.5vh + 0.5vw) 0 calc(1vh + 1vw) 0;
}

.fiiDiiTable table {
    border-collapse: collapse;
}

.fiiDiiTable th,
.fiiDiiTable td {
    border: 1px solid white;
}

.heading {
    font-weight: 700;
    font-size: calc(1.5vh + 1.5vw);
    margin: 1.5vh 0;
}
.tableHeader {
    text-transform: uppercase;
    font-size: calc(0.5vh + 0.5vw);
}

.tableHeader th {
    font-weight: 500;
    padding: calc(0.5vh + 0.5vw);
}

.tableHeader th:first-child {
    visibility: hidden;
    border: none;
}

.tableBody {
    font-weight: 700;
    font-size: calc(1vh + 1vw);
}

.tableBody tr td {
    text-align: left;
    padding: 0.2rem 2rem;
    width: 12vw;
}

.tableRowSpan {
    font-size: calc(2vh + 2vw);
}

.trendGreen {
    color: #65b741;
    margin-left: -100;
    vertical-align: middle;
}
.trendRed {
    color: rgb(255, 0, 0);
    margin-left: -100;
    vertical-align: middle;
}

.trendGreen1 {
    background-color: #65b741;
    margin-left: -100;
    vertical-align: middle;
}
.trendRed1 {
    background-color: rgb(255, 0, 0);
    margin-left: -100;
    vertical-align: middle;
}

.chartGroup {
    margin-top: 5vh;
}

.charts {
    margin-top: 3vh;
}
