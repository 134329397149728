.cashMarketLongTerm {
}

.longTermContainer {
    background-color: #1e2124;
    padding: calc(0.5vh + 0.5vw);
}

.longTermInnerContainer {
    background-color: #404040;
    padding: calc(0.5vh + 0.5vw);
}

.indexSummary {
    display: flex;
    flex-direction: row;
    width: 60%;
    margin: auto;
    justify-content: space-evenly;
    margin-top: 2rem;
}
