.navbar {
    font-weight: 700;
    height: 15vh;
    width: 100%;
}

/* Ticker Class*/
.ticker {
    font-weight: 700;
}
.announcement {
    visibility: hidden;
    height: 0;
    display: flex;
    align-items: center;
    padding: 1vh 0;
}
.announcementText {
    display: inline-flex;
    align-items: center;
    margin-right: 1vw;
    font-size: calc(0.5vh + 0.5vw);
}
.announcementText span {
    margin-right: 0.5vw;
}
.announcementText span:not(:last-child) {
    margin-right: 0.5vw;
}
.announcementText span:last-child {
    margin-right: 1vw;
}
.greeting {
    visibility: hidden;
    height: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1vh 0;
    font-weight: 700;
    font-size: calc(0.6vh + 0.6vw);
}
.trendGreen {
    color: rgb(0, 255, 0);
    vertical-align: middle;
}
.trendRed {
    color: rgb(255, 0, 0);
    vertical-align: middle;
}
.arrowUp {
    vertical-align: middle;
    margin-right: 0.2vw;
}
.arrowDown {
    vertical-align: middle;
    margin-right: 0.2vw;
}

/* Header Class */
.header {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    max-width: 100%;
    width: 100%;
    padding: 0 5vw;
    box-sizing: border-box;
    font-size: calc(0.7vh + 0.7vw);
}

/* Logo Class */
.logo {
    display: inline-flex;
    align-items: center;
    margin-left: -1.5vw;
}
.logoImage {
    width: 6vw;
    transform: translate(1vw);
}
.logoText {
    width: 8vw;
}

/* Menu Class - Thunder One to Product*/
.menu {
    display: inline-flex;
    align-items: center;
}
.thunderOne {
    display: inline-flex;
    align-items: center;
    margin: 0 1vw;
}
.thunderLogo {
    width: 2vw;
}
.menuContent {
    margin: 0 1vw;
}

/* Authentication Class login signup*/
.authentication {
    display: inline-flex;
    align-items: center;
    visibility: hidden;
}

.login {
    margin: 0 1vw 0 0;
}
.signup {
    background-color: #ff006e;
    padding: calc(0.2vh + 0.2vw);
    padding-bottom: 1vh;
    border-radius: calc(0.5vh + 0.5vw);
    vertical-align: middle;
}
