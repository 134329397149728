.RegisterBase {
    margin-top: 5vh;
    height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    font-family: "Montserrat", sans-serif;
}

.registerHeader {
    font-size: calc(1.5vh + 1.5vw);
    font-weight: 500;
}

.innerRegister {
    margin-top: 5vh;
    height: 50vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    /* background-color: white; */
}

.textFieldRegister {
    color: "white";
    font-family: "Montserrat", sans-serif;
}

.buttonContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
}
