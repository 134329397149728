.indicesWrapper {
    margin: 0.5rem;
    padding: 0.5rem;
    border-radius: 1rem;
    width: 11vw;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    background-color: #2a2e39;
}

.indicesWrapper img {
    border-radius: 50%;
    float: right;
    height: 55%;
}

.indicesWrapperData {
    margin-top: 0.5vh;
    font-size: calc(0.7vh + 0.7vw);
    line-height: 3vh;
    margin-left: 0.5vw;
}

.trendGreen {
    color: rgb(0, 255, 0);
    margin-left: -100;
    vertical-align: middle;
}
.trendRed {
    color: rgb(255, 0, 0);
    margin-left: -100;
    vertical-align: middle;
}
.arrowUp {
    vertical-align: middle;
    margin-right: 0.2rem;
}
.arrowDown {
    vertical-align: middle;
    margin-right: 0.2rem;
}
