.fiiDii {
    height: 77vh;
}
.fiiDiiCash {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 77vh;
}

.fiiDiiCashTableContainer {
    background-color: #1e2124;
    padding: calc(0.8vh + 0.8vw);
}

.fiiDiiCashTable {
    background-color: #404040;
    border-radius: calc(0.5vh + 0.5vw);
}
.fiiDiiCashTableHeader {
    text-transform: uppercase;
    font-size: 2.5rem;
}

.fiiDiiCashTableHeader th {
    font-weight: 900;
}

.fiiDiiCashTableHeader th div {
    font-size: 0.7rem;
}

.fiiDiiCashTableBody {
    font-weight: 900;
    font-size: 1.5rem;
}
.trendGreen {
    color: rgb(0, 255, 0);
    margin-left: -100;
}
.trendRed {
    color: rgb(255, 0, 0);
    margin-left: -100;
}

.fiiDiiCashTableBody div {
    background-color: #3d3333;
    height: 100%;
    vertical-align: middle;
    line-height: 10vh;
    border-radius: calc(0.5vh + 0.5vw);
}

.fiiDiiCashTable {
    border-collapse: collapse;
}

.fiiDiiCashTableHeader th,
.fiiDiiCashTableBody td {
    border: none;
    height: 10vh;
    width: 28vw;
    padding: calc(0.5vh + 0.5vw);
    text-align: center;
}

.fiiDiiCashTableHeader,
.fiiDiiCashTableHeader th {
    position: relative;
}

.fiiDiiCashTableHeader::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0.5%; /* Adjust this value to control the starting point of the pseudo-element */
    width: 99%; /* Adjust this value to control the width of the pseudo-element */
    border-bottom: calc(0.1vh + 0.1vw) solid white; /* Set the border for the pseudo-element */
}

.fiiDiiCashTableHeader th::after {
    content: "";
    background-color: white;
    position: absolute;
    right: 0%;
    top: 10%;
    bottom: 10%;
    width: calc(0.1vh + 0.1vw);
}

.fiiDiiCashTableHeader th:last-child::after {
    content: none;
}

.fiiDiiCashTableBody tr td {
    position: relative;
}

.fiiDiiCashTableBody tr td::before {
    content: "";
    position: absolute;
    bottom: 10%;
    right: 0%;
    height: 80%;
    border-left: calc(0.1vh + 0.1vw) solid white;
}

.fiiDiiCashTableBody tr td:last-child::before {
    border: none;
}

.fiiDiiCashTableBody tr:last-child td::before {
    border: none;
}

.fiiDiiCashTableBody tr td::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 1.5%;
    width: 97%;
    border-bottom: calc(0.1vh + 0.1vw) solid white;
    /* border-left: calc(0.1vh + 0.1vw) solid white; */
}

.fiiDiiCashTableBody tr:last-child td::after {
    border: none;
}

.netChangePositive {
    background-color: rgb(0, 255, 0);
}

.netChangeNegative {
    background-color: rgb(255, 0, 0);
}

.netChangePositive,
.netChangeNegative {
    margin-top: calc(0.8vh + 0.8vw);
    color: #ffffff;
    text-shadow: 0.2vw 0.2vh calc(0.1vh + 0.1vw) black;
    border-radius: calc(0.5vh + 0.5vw);
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: calc(1vh + 1vw);
    padding: calc(1vh + 1vw);
    font-weight: 900;
}

/* Secomd Page */
.cashMarketDailySummary {
    margin-top: 5vh;
    height: 95vh;
}

.summaryContainer {
    background-color: #1e2124;
    padding: calc(1vh + 1vw);
}

.summaryTitle {
    font-size: calc(1.5vh + 1.5vw);
    font-weight: 700;
}

.summaryTable {
    border-collapse: collapse;
    border-radius: calc(0.5vh + 0.5vw);
    border-style: hidden;
    box-shadow: 0 0 0 1px #666;
    width: 100%;
    margin-top: 2vh;
}

.summaryTableHeader {
    font-size: calc(0.8vh + 0.8vw);
}

.summaryTableBody {
    font-size: calc(0.6vh + 0.6vw);
}

.summaryTableBody tr:nth-child(2n + 1) {
    background-color: #404040;
}

.summaryTableHeader th:first-child {
    text-align: left;
    /* padding-left: calc(1vh + 1vw); */
}

.summaryTableHeader th {
    text-align: right;
    padding: calc(0.5vh + 0.5vw);
}
.summaryTableBody tr td {
    text-align: right;
    padding: 1.2vh calc(0.5vh + 0.5vw);
}

.summaryTableBody tr td:first-child {
    text-align: left;
}

.negativeNet {
    color: rgb(255, 0, 0);
}

.positiveNet {
    color: rgb(0, 255, 0);
}
