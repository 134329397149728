.firstSection {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    height: 85vh;
    width: 100%;
    overflow: hidden;
}

.firstSectionHeader {
    margin-top: 8vh;
    display: inline-flex;
    align-items: center;
    margin-left: 5vw;
}

.oneLiner {
    font-size: calc(7vh + 7vw);
    line-height: 85%;
    font-weight: 900;
}

.moneyLogo {
    margin-left: 5vw;
    width: 45vw;
}

.firstSectionContent {
    margin-top: 2vh;
    font-size: calc(0.5vh + 0.5vw);
    max-width: 37%;
    font-weight: 700;
    text-align: justify;
    margin-left: 5vw;
}

.firstSectionFooter {
    margin-top: 2vh;
    font-size: calc(1.2vh + 1.2vw);
    color: #000000;
    font-weight: 900;
    background-color: #0077b6;
    padding: calc(0.3vh + 0.3vw);
    padding-bottom: 0.8vh;
    border-radius: calc(0.5vh + 0.5vw);
    margin-left: 5vw;
}

/* Second Section */
.secondSection {
    display: inline-flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: #131722;
    width: 100%;
    align-items: center;
    height: 100vh;
    font-family: "Montserrat", sans-serif;
}

/*Header*/
.secondSectionHeader {
    display: inline-flex;
    flex-direction: row;
    font-size: calc(1.8vh + 1.8vw);
    font-weight: 600;
    justify-content: space-evenly;
    width: 50%;
}

/*Content*/
.secondSectionContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: calc(0.7vh + 0.7vw);
    text-transform: capitalize;
    font-weight: 400;
    margin-top: -5vh;
}

.contentSectionHeader {
    font-size: calc(1.3vh + 1.3vw);
    margin-bottom: 3vh;
}

/*Footer*/
.secondSectionFooter {
    display: flex;
    flex-direction: row;
    font-size: calc(0.7vh + 0.6vw);
    font-weight: 500;
    justify-content: space-between;
    align-items: center;
    width: 70%;
    text-transform: capitalize;
    margin-top: -5vh;
}

.rightSecondSection {
    margin-left: 10vw;
}

.liveClassSection,
.courseSection,
.youtubeSection {
    background-color: #d9d9d9;
    color: #000000;
}

.liveClassSection {
    width: 30 vw;
    height: 54vh;
    border-radius: calc(0.8vh + 0.8vw);
    /* border-bottom-left-radius: calc(0.8vh + 0.8vw);
    border-bottom-right-radius: calc(0.8vh + 0.8vw); */
}

.courseSection,
.youtubeSection {
    width: 80%;
    display: inline-flex;
    flex-direction: row;
    height: 11vw;
    align-items: center;
}

.courseSection {
    border-radius: calc(0.8vh + 0.8vw);
    /* border-top-left-radius: calc(0.8vh + 0.8vw);
    border-bottom-left-radius: calc(0.8vh + 0.8vw); */
}

.youtubeSection {
    margin-top: 8vh;
    border-radius: calc(0.8vh + 0.8vw);
    /* border-top-right-radius: calc(0.8vh + 0.8vw);
    border-bottom-right-radius: calc(0.8vh + 0.8vw); */
}

.learnContent {
    padding: 1vw;
}

.footerCardsHeader {
    font-size: calc(1vh + 0.8vw);
    font-weight: 600;
    margin-bottom: 1vh;
}

.liveClassesLogo {
    width: 30vw;
    border-top-left-radius: calc(0.8vh + 0.8vw);
    border-top-right-radius: calc(0.8vh + 0.8vw);
}

.courseLogo,
.youtubeLogo {
    width: 11vw;
    border-top-right-radius: calc(0.8vh + 0.8vw);
    border-bottom-right-radius: calc(0.8vh + 0.8vw);
}

.courseLogo {
    display: flex;
    align-items: center;
}

.youtubeLogo {
    padding-left: 0.5vw;
}

.youtubeLogo:hover {
    cursor: pointer;
}

.thirdSection {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    align-items: center;
    height: 100vh;
    font-family: "Montserrat", sans-serif;
    text-transform: capitalize;
    letter-spacing: 0.3vh;
    line-height: 1.5;
}

.rightThirdSection {
    width: 30vw;
    margin-left: 3vw;
}

.leftThirdSection img {
    width: 50vw;
    height: 60vh;
}

.thirdSectionHeader {
    font-size: calc(1.5vh + 1.5vw);
    font-weight: 500;
    margin-bottom: 2vh;
}
.thirdSectionInternalContainer {
    margin-left: 0.5vw;
}

.thirdSectionInternal {
    border-left: white solid;
    padding-left: 2vh;
}

.thirdSectionInternalHeader {
    margin-top: 5vh;
    font-size: calc(1vh + 1vw);
    font-weight: 500;
    margin-bottom: 1vh;
}
