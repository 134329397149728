.FIIOptionTable {
    margin: 2vh 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.optionTable span {
    cursor: pointer;
    background-color: #363636;
    padding: 2vh;
    font-size: calc(0.5vh + 0.5vw);
    border-right: calc(0.1vh + 0.1vw) solid #5b5b5b;
}

.optionTable span:first-child {
    border-radius: calc(0.2vh + 0.2vw) 0 0 calc(0.2vh + 0.2vw);
}

.optionTable span:last-child {
    border-radius: 0 calc(0.2vh + 0.2vw) calc(0.2vh + 0.2vw) 0;
    border: none;
}

.optionDate span {
    padding: 2vh 4vw;
    background-color: #363636;
    font-size: calc(0.6vh + 0.6vw);
    border-radius: calc(0.2vh + 0.2vw);
    font-family: "Montserrat", sans-serif;
    letter-spacing: 0.1vw;
}
