.pagination-container {
    display: flex;
    list-style-type: none;

    .pagination-arrow {
        text-align: center;
        margin: auto 0;
        display: flex;
        align-items: center;
        border-radius: calc(1vh + 1vw);
        font-size: calc(0.7vh + 0.7vw);
        color: #a09d9d;
        width: 2vw;
        height: 4vh;

        &:hover {
            background-color: #dfdede;
            color: #646363;
            cursor: pointer;
        }

        &.disabled {
            pointer-events: none;
            &:hover {
                background-color: transparent;
                cursor: default;
            }
        }
    }

    .pageination-input {
        background-color: #404040;
        border: none;
        color: white;
        text-align: center;
        font-size: calc(0.6vh + 0.6vw);
        width: 2vw;
        padding: calc(0.2vh + 0.2vw);
        margin: 0 0.5vw;
        outline: none;
        border-radius: calc(0.1vh + 0.1vw);
    }

    .pagination-item {
        font-size: calc(0.6vh + 0.6vw);
        margin: 0 calc(0.4vh + 0.4vw);
    }
}
