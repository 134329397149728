.optionChain {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pageHeader {
    margin: auto;
    font-size: 1rem;
    font-weight: 900;
    text-transform: uppercase;
}
.indexSummary {
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
}

.searchFields {
    justify-content: space-between;
    display: flex;
    width: 70vw;
}

.searchSymbol,
.searchExpiry {
    margin: 2rem;
}

.searchSymbol {
    margin-right: auto;
}

.searchExpiry {
    margin-left: auto;
    text-align: right;
}

.searchBox,
.expiryBox {
    background-color: white;
    width: 15rem;
    border-radius: 0.5rem;
    /* position: absolute; */
    /* z-index: 100; */
}

/* table,
th,
td {
    border: 1px solid white;
    border-collapse: collapse;
}

th,
td {
    font-size: 1rem;
    padding: 0.5rem 2rem;
    align-items: center;
} */

.tableWrapper {
    width: 70vw;
    height: 44vh;
    overflow-y: auto;
    z-index: -1;
}
.tableWrapper table {
    /* border-collapse: collapse; */
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
}

th,
td {
    border: 1px solid #5b5b5b;
    text-align: center;
    padding: 0.2rem;
}

th {
    padding: 0.5rem;
}

.tableHeader {
    height: 5vh;
    position: sticky;
    top: 0;
    text-transform: uppercase;
}

.tableHeader th:nth-child(1) {
    background-color: #ffcccb;
    color: red;
}

.tableHeader th:nth-child(2) {
    background-color: #9eff9e;
    color: green;
}

.tableSubHeader {
    background-color: #343434;
    position: sticky;
    top: 5vh;
}
